@keyframes bubble {
	0%, 60%, 75%, 90%, 100% { transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000); }
	0% { opacity: 0; transform: translate3d(0, 300px, 0) scale(0); }
	60% { opacity: 1; transform: translate3d(0, -20px, 0) scale(1); }
	75% { transform: translate3d(0, 10px, 0); }
	90% { transform: translate3d(0, -5px, 0); }
	100% { transform: translate3d(0, 0, 0); }
}

@keyframes bubble-delete {
	0% { opacity: 1; }
	50% { opacity: 0; transform: scale3d(.3, .3, .3); }
	100% { opacity: 0; }
}

@keyframes armGnome {
	0% { transform: rotate(0deg); }
	25% { transform: rotate(0deg); }
	50% { transform: rotate(-90deg); }
	75% { transform: rotate(0deg); }
	100% { transform: rotate(0deg); }
}

@keyframes bodyGnome {
	0% { transform: rotate(0deg); }
	25% { transform: rotate(0deg); }
	50% { transform: rotate(2deg); }
	52% { transform: rotate(1deg); }
	75% { transform: rotate(0deg); }
	100% { transform: rotate(0deg); }
}

@keyframes carGnome {
	0% { top:27px; }
	25% { top:150px; }
	26% { opacity:0; top:27px; }
	64% { opacity:0;}
	67% { opacity:1;}
	75% { opacity:1;}
	100% { opacity:1;}
}

@keyframes eyesGnome {
	0% { transform: scaleY(1);}
	34% { transform: scaleY(1);}
	35% { transform: scaleY(0.5);}
	36% { transform: scaleY(1);}
	54% { transform: scaleY(1);}
	55% { transform: scaleY(0.5);}
	56% { transform: scaleY(1);}
	77% { transform: scaleY(1);}
	100% { transform: scaleY(1);}
}

@keyframes rubberBand {
	0% { transform: scale3d(0, 0, 0); }
	10% { transform: scale3d(1, 1, 1); }
	30% { transform: scale3d(1.25, 0.75, 1); }
	40% { transform: scale3d(0.75, 1.25, 1); }
	50% { transform: scale3d(1.15, 0.85, 1); }
	65% { transform: scale3d(.95, 1.05, 1); }
	75% { transform: scale3d(1.05, .95, 1); }
	100% { transform: scale3d(1, 1, 1); }
}

@keyframes bounceInUp {
	0%, 60%, 75%, 90%, 100% { transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000); }
	0% { opacity: 0; transform: translate3d(0, 3000px, 0); }
	60% { opacity: 1; transform: translate3d(0, -20px, 0); }
	75% { transform: translate3d(0, 10px, 0); }
	90% { transform: translate3d(0, -5px, 0); }
	100% { transform: translate3d(0, 0, 0); }
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes blink {
	5% { transform: scaleY(1); }
	10% { transform: scaleY(0.2); }
	15% { transform: scaleY(1); }
	100% { transform: scaleY(1); }
}

@keyframes eyebrow {
	5% { transform: translateY(0); }
	10% { transform: translateY(5px); }
	15% { transform: translateY(0); }
	100% { transform: translateY(0); }
}

@keyframes left-stache-shake {
	0% { transform: rotateZ(0); }
	1% { transform: rotateZ(5deg); }
	2% { transform: rotateZ(0); }
	3% { transform: rotateZ(5deg); }
	4% { transform: rotateZ(0); }
	5% { transform: rotateZ(5deg); }
	6% { transform: rotateZ(0); }
	100% { transform: rotateZ(0); }
}

@keyframes right-stache-shake {
	0% { transform: rotateZ(0); }
	1% { transform: rotateZ(-5deg); }
	2% { transform: rotateZ(0); }
	3% { transform: rotateZ(-5deg); }
	4% { transform: rotateZ(0); }
	5% { transform: rotateZ(-5deg); }
	6% { transform: rotateZ(0); }
	100% { transform: rotateZ(0); }
}

@keyframes tickle-body { 0% { transform: scale(1); } 50% { transform: scale(0.99); } 100% { transform: scale(1); } }

@keyframes tickle-leg { 0% { transform: translateY(0); } 50% { transform: translateY(-5px); } 100% { transform: translateY(0); } }

@keyframes tickle-arm { 0% { transform: translateY(0); } 50% { transform: translateY(2%); } 100% { transform: translateY(0); } }

@keyframes tickle-head { 0% { transform: translateY(0); } 50% { transform: translateY(2%); } 100% { transform: translateY(0); } }

@keyframes tickle-stache-left { 0% { transform: rotateZ(0); } 50% { transform: rotateZ(5deg); } 100% { transform: rotateZ(0); } }

@keyframes tickle-stache-right { 0% { transform: rotateZ(0); } 50% { transform: rotateZ(-5deg); } 100% { transform: rotateZ(0); } }

@keyframes tickle-eye { 0% { transform: scaleY(1); } 100% { transform: scaleY(0.2); } }

@keyframes tickle-eyebrow { 0% { transform: translateY(0); } 100% { transform: translateY(5px); } }

@keyframes head-shaking {
	0% { transform: rotateZ(0deg); }
	30% { transform: rotateZ(3deg); }
	40% { transform: rotateZ(1deg); }
	50% { transform: rotateZ(3deg); }
	60% { transform: rotateZ(1deg); }
	100% { transform: rotateZ(0deg); }
}

@keyframes hand-shaking {
	0% { transform: rotateZ(0deg); }
	20% { transform: rotateZ(-35deg); }
	40% { transform: rotateZ(-8deg); }
	60% { transform: rotateZ(-38deg); }
	80% { transform: rotateZ(-6deg); }
	100% { transform: rotateZ(-40deg); }
}

@keyframes group-shaking {
	0% { transform: rotateZ(6deg); }
	100% { transform: rotateZ(-6deg); }
}

@keyframes group-hand {
	0% { transform: rotateZ(-45deg); }
	100% { transform: rotateZ(-15deg); }
}

@keyframes bellShaking {
	0% { transform: rotateZ(10deg); }
	50% { transform: rotateZ(0deg); }
	100% { transform: rotateZ(10deg); }
}

@keyframes writing {
	0% { transform: rotateZ(2deg); }
	50% { transform: rotateZ(0deg); }
	100% { transform: rotateZ(2deg); }
}

@keyframes swing {
	20% { transform: rotate3d(0, 0, 1, 15deg); }
	40% { transform: rotate3d(0, 0, 1, -10deg); }
	60% { transform: rotate3d(0, 0, 1, 5deg); }
	80% { transform: rotate3d(0, 0, 1, -5deg); }
	100% { transform: rotate3d(0, 0, 1, 0deg); }
}

@keyframes flame {
	0% { transform: scale(1); }
	50% { transform: scale(1.05); fill: #f3cc5c; }
	100% { transform: scale(1); }
}

@keyframes snowFall {
	0% { background-position: center 0; }
	100% { background-position: center 500px; }
}
