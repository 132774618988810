@import "_tools.scss";

/*
* Bandeau deconnection
*/
.logout-notification {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	transform: translateY(-100px);

	align-items: center;
	background: #fefaf7;
	border: 0;
	border-radius: 0;
	box-shadow: 0 rem(2px) rem(3px) 0 rgba(0, 0, 0, .35);
	color: #000;
	display: flex;
	font-size: rem(16px);
	height: rem(50px);
	margin: 0;
	text-align: center;
	justify-content: center;
	width: 100%;
	z-index: 2;

	animation-name: notification;
	animation-duration: 4s;
	animation-iteration-count: 1;
}

@keyframes notification {
	0%   { transform: translateY(-100px); }
	10%  { transform: translateY(0px); }
	70%  { transform: translateY(0px); }
	100% { transform: translateY(-100px); }
}

/*
* Bandeau listes visitées
*/
.visited-notification {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;

	align-items: center;
	background: #fefaf7;
	border: 0;
	border-radius: 0;
	box-shadow: 0 rem(2px) rem(3px) 0 rgba(0, 0, 0, .35);
	color: #000;
	display: flex;
	font-size: rem(16px);
	min-height: rem(50px);
	margin: 0;
	text-align: center;
	justify-content: center;
	width: 100%;
	z-index: 2;

	animation-name: visited-notification;
	animation-duration: 1s;
	animation-iteration-count: 1;

	p {
		font-size: rem(14px);
		margin-bottom: 0;
		padding: rem(10px) rem(40px);
		width: 100%;

		a {
			display: inline-block;
		}
	}

	button {
		background: #FFF;
		border: 1px solid #444;
		border-radius: 5px;
		color: #333;
		cursor: pointer;
		display: block;
		font-size: rem(18px);
		height: rem(22px);
		line-height: 0;
		padding: 0;
		position: fixed;
		right: rem(10px);
		text-align: center;
		top: rem(15px);
		width: rem(22px);

		&:hover {
			border-color: #c43c3c;
			color: #c43c3c;
		}

		&:active {
			transform: translateY(2px);
		}
	}
}

@keyframes visited-notification {
	0%   { transform: translateY(-100px); }
	100%  { transform: translateY(0px); }
}

.notification--dismiss {
	animation-name: notification-dismiss;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

@keyframes notification-dismiss {
	0%   { transform: translateY(0px); }
	100%  { transform: translateY(-100px); }
}

.visited-notification + .homepage-header .content .home-header__connect {
	top: rem(60px);
}


.homepage .content {
	text-align: center;
}


/*
* Intro
*/
.homepage-intro {
	display: flex;
	font-size: 1.375em;
	font-weight: 500;
	justify-content: center;
	margin: rem(-30px) rem(-20px) 0;
	overflow: hidden;
	text-align: center;
	width: calc(100% + #{rem(40px)});

	@media only screen and (max-width: rem(600px)) {
		margin-top: calc(#{(100 / 600) * 1vw});
	}

	svg {
		width: 100%;
		min-width: rem(1200px);

		path {
			fill: transparent;
		}

		text {
			font-size: rem(22px);
			font-weight: 500;
			text-align: center;
			transform: translateY(#{rem(15px)});

			@media only screen and (max-width: rem(660px)) {
				font-size: rem(14px);
			}

			@media only screen and (max-width: rem(420px)) {
				font-size: rem(11px);
			}
		}
	}
}


/*
* Animations (Xmas / Holiday)
*/
@import "animations.scss";

.homepage-illustration.illustration-xmas {
	background: url(/images/homepage/xmas/home-bg.jpg) no-repeat center top;
	height: 100%;
	left: 0;
	margin: 0 rem(-20px);
	min-height: rem(617px);
	position: relative;
	overflow: hidden;
	top: 0;
	width: calc(100% + #{rem(40px)});

	/* Pere Noel */
	.santa .body,
	.santa .head,
	.santa .left-arm,
	.santa .eye--left,
	.santa .eyebrow--left,
	.santa .left-leg,
	.santa .stache--left,
	.santa .letter,
	.santa .nose,
	.santa .right-arm,
	.santa .eye--right,
	.santa .eyebrow--right,
	.santa .right-leg,
	.santa .stache--right {
		background: url(/images/homepage/xmas/santa-sprite.png) no-repeat;
		display: block;
		position: absolute;
	}

	.santa { position: relative; top:87px; left: 50%; display: block; width: 1061px; height: 535px; margin-left: -530px; }
	.santa .character { position: absolute; left: 300px; top: 66px; width: 396px; height: 340px; }
	.santa .body { left: 35px; top: 55px; width: 300px; height: 258px; background-position: 0 -517px; }
	.santa .left-arm { left: 0; top: 69px; z-index: 2; width: 202px; height: 164px; background-position: 0 0; }
	.santa .left-leg { left: 32px; top: 259px; width: 124px; height: 81px; background-position: 0 -1198px; }
	.santa .right-leg { left: 266px; top: 231px; width: 76px; height: 78px; background-position: 0 -1279px; }
	.santa .right-arm { left: 210px; top: 57px; z-index: 2; width: 186px; height: 163px; background-position: 0 -354px; }
	.santa .letter { left: 171px; top: 123px; z-index: 1; width: 132px; height: 278px; background-position: 0 -775px; pointer-events: none; }
	.santa .character-head { position: absolute; left: 132px; top: 0; width: 146px; height: 190px; }
	.santa .head { position: absolute; left: 0; top: 0; width: 143px; height: 190px; background-position: 0 -164px; }
	.santa .nose { position: absolute; left: 58px; top: 67px; width: 40px; height: 21px; background-position: 0 -1357px; }
	.santa .stache--left { position: absolute; left: 2px; top: 54px; width: 78px; height: 63px; background-position: 0 -1135px; animation: left-stache-shake 10s; animation-delay: 5s; animation-fill-mode: both; transform-origin: right center; }
	.santa .stache--right { position: absolute; left: 78px; top: 28px; width: 68px; height: 82px; background-position: 0 -1053px; animation: right-stache-shake 10s; animation-delay: 5s; animation-fill-mode: both; transform-origin: left center; }
	.santa .eye { width: 12px; height: 6px; animation: blink 3s infinite; animation-fill-mode: both; transform-origin: center bottom; }
	.santa .eye--left { position: absolute; left: 58px; top: 65px; background-position: 0 -1417px; }
	.santa .eye--right { position: absolute; left: 81px; top: 62px; background-position: 0 -1411px; }
	.santa .eyebrow { width: 14px; animation: eyebrow 3s infinite; animation-fill-mode: both; }
	.santa .eyebrow--left { left: 60px; top: 32px; height: 18px; background-position: 0 -1378px; }
	.santa .eyebrow--right { left: 81px; top: 34px; height: 15px; background-position: 0 -1396px; }
	.santa .ticklish-zone { position: absolute; left: 180px; top: 70px; width: 60px; height: 40px; z-index:3; cursor:pointer; background:rgba(0,0,0,0); }
	.santa .ticklish-zone:before { content: ''; position: absolute; left: -120px; top: 210px; display: block; width: 60px; height: 60px; z-index:3; cursor:pointer; background:rgba(0,0,0,0); }
	.santa .ticklish-zone:after { content: ''; position: absolute; left: 120px; top: 180px; display: block; width: 60px; height: 60px; z-index:3; cursor:pointer; background:rgba(0,0,0,0); }

	/* Pere Noel chatouilleux */
	.santa .ticklish-zone:hover ~ .body { animation: tickle-body infinite 0.5s; transform-origin: center bottom; }
	.santa .ticklish-zone:hover ~ .right-leg { animation: tickle-leg 0.3s infinite; }
	.santa .ticklish-zone:hover ~ .left-leg { animation: tickle-leg 0.3s infinite; animation-delay: 0.2s; }
	.santa .ticklish-zone:hover ~ .left-arm { animation: tickle-arm 0.5s infinite; }
	.santa .ticklish-zone:hover ~ .right-arm { animation: tickle-arm 0.5s infinite; }
	.santa .ticklish-zone:hover ~ .letter { animation: tickle-arm 0.5s infinite; }
	.santa .ticklish-zone:hover ~ .character-head { animation: tickle-head 0.5s infinite; transform-origin: center bottom; }
	.santa .ticklish-zone:hover ~ .character-head .stache--left { animation: tickle-stache-left 0.3s; transform-origin: right center; }
	.santa .ticklish-zone:hover ~ .character-head .stache--right { animation: tickle-stache-right 0.3s; transform-origin: left center; animation-delay: 0.2s;}
	.santa .ticklish-zone:hover ~ .character-head .eye { animation: tickle-eye 0.3s; animation-fill-mode: forwards; }
	.santa .ticklish-zone:hover ~ .character-head .eyebrow { animation: tickle-eyebrow 0.3s; animation-fill-mode: forwards; }

	/* Lutin */
	.imp { position: absolute; left: 50%; top: 248px; margin-left: 300px; width: 130px; height: 173px; }
	.imp .body { position: absolute; left: 0; bottom: 0; }
	.imp .head { animation: head-shaking 2s infinite; animation-fill-mode: both; transform-origin: center bottom; }
	.imp .bell { position: absolute; left: 109px; top: 24px; animation: bellShaking 1s infinite; animation-fill-mode: both; transform-origin: center top; }
	.imp .writing-arm { position: absolute; left: 35px; top: 85px; width: 41px; height: 47px; animation: writing 0.5s infinite; animation-fill-mode: both; transform-origin: right bottom; }
	.imp .notepad { position: absolute; left: 1px; top: 80px; }

	/* Chaussette */
	.socks { position: absolute; left: 50%; top: 159px; z-index: 3; display: block; width: 51px; height: 100px; margin-left: -365px; background: url(/images/homepage/xmas/socks.png) no-repeat; animation-duration: 1s; animation-fill-mode: both; transform-origin: top center; }
	.socks:hover { animation-name: swing; }

	/* Feu */
	#fire { position: absolute; left: 50%; top: 233px; width: 120px; height: 115px; margin-left: -440px; }
	#fire .flame { animation: flame 2s infinite; animation-fill-mode: both; transform-origin: center bottom; }

	/* Fenetre */
	.window { position: absolute; left: 50%; top: 35px; width: 184px; height: 211px; margin-left: 335px; }
	.wood { position: absolute; left: 0; top: 0; }
	.outside { position: absolute; left: 10%; top: 10%; right: 10%; bottom: 10%; border-radius: 90px; background: #b1b56a; }
	.snow-01 { position: absolute; left: 10%; top: 10%; right: 10%; bottom: 10%; border-radius: 90px; background: url(/images/homepage/xmas/snow-01.png) repeat-y center bottom; animation: snowFall linear infinite 15s; }
	.snow-02 { position: absolute; left: 10%; top: 10%; right: 10%; bottom: 10%; border-radius: 90px; background: url(/images/homepage/xmas/snow-02.png) repeat-y center bottom; animation: snowFall linear infinite 15s; }
	.snow-03 { position: absolute; left: 10%; top: 10%; right: 10%; bottom: 10%; border-radius: 90px; background: url(/images/homepage/xmas/snow-03.png) repeat-y center bottom; animation: snowFall linear infinite 15s; }
}


.homepage-illustration.illustration-holiday {
	background: url(/images/homepage/holiday/home-bg.jpg) no-repeat center top;
	height: 100%;
	left: 0;
	margin: 0 rem(-50px);
	min-height: rem(617px);
	overflow: hidden;
	position: relative;
	top: 0;
	width: calc(100% + #{rem(100px)});

	.background { position: relative; display: block; width: 100%; left: 50%; margin-left: -644px; }
	.background .palm-tree { position: absolute; left:1065px; top:115px; }
	.background .imp-hat { position: absolute; left:1110px; top:363px; }

	.group { position: relative; top: 300px; left: 180px; }
	.group .woman1 { position: absolute; display: block; width: 61px; left: 0px; }
	.group .woman1 .woman-head     { position: absolute; left: 12px; top: 0px; }
	.group .woman1 .woman-skirt    { position: absolute; left: 5px; top: 50px; }
	.group .woman1 .woman-body     { position: absolute; left: 0px; top: 18px; }
	.group .woman1 .woman-pedestal { position: absolute; left: 7px; top: 72px; }
	.group .man { position: absolute; display: block; width: 58px; left: 60px; }
	.group .man .man-arm { position: absolute; left: 2px; top: 31px; }
	.group .woman2 { position: absolute; display: block; width: 61px; left: 115px; }
	.group .woman2 .woman-head     { position: absolute; left: 12px; top: 0px; }
	.group .woman2 .woman-skirt    { position: absolute; left: 5px; top: 50px; }
	.group .woman2 .woman-body     { position: absolute; left: 0px; top: 18px; }
	.group .woman2 .woman-pedestal { position: absolute; left: 7px; top: 72px; }

	/* Danse */
	.group .woman1 .woman-body  { animation: group-shaking 1.25s linear infinite alternate;         transform-origin: center center; }
	.group .woman1 .woman-skirt { animation: group-shaking 1.25s linear infinite alternate-reverse; transform-origin: center top; }
	.group .woman1 .woman-head  { animation: group-shaking 1.25s linear infinite alternate-reverse; transform-origin: center center; }
	.group .woman2 .woman-body  { animation: group-shaking 1.25s linear infinite alternate-reverse; transform-origin: center center; }
	.group .woman2 .woman-skirt { animation: group-shaking 1.25s linear infinite alternate;         transform-origin: center top; }
	.group .woman2 .woman-head  { animation: group-shaking 1.25s linear infinite alternate;         transform-origin: center center; }
	.group .man-arm             { animation: group-hand .75s linear infinite alternate;            transform-origin: 8px 6px; }

	.santa { position: relative; display: block; }
	.santa .chair { position: absolute; top: 100px; left: 50%; display: block; width: 797px; height: 498px; margin-left: -378px; }
	.santa .character { position: absolute; top: 143px; left: 50%; display: block; width: 457px; height: 370px; margin-left: -206px; }

	.santa .character .nose,
	.santa .character .stache-right,
	.santa .character .stache-left,
	.santa .character .glasses,
	.santa .character .hand-left,
	.santa .character .hand-right,
	.santa .character .head,
	.santa .character .body,
	.santa .character .pant-top,
	.santa .character .leg-left,
	.santa .character .leg-right,
	.santa .character .pant-bottom { display: block; background: url(/images/homepage/holiday/santa/santa-sprite.png) no-repeat; position: absolute; }

	.santa .character .pant-bottom    { background-position: -0px -305px;   width: 318px; height: 51px;  position: absolute; left: 40px;   top: 262px; }
	.santa .character .leg-left       { background-position: -0px -554px;   width: 111px; height: 77px;  position: absolute; left: 54px;   top: 281px; }
	.santa .character .leg-right      { background-position: -319px -223px; width: 85px;  height: 71px;  position: absolute; left: 272px;  top: 260px; }
	.santa .character .pant-top       { background-position: -0px -223px;   width: 319px; height: 82px;  position: absolute; left: 40px;   top: 230px; }
	.santa .character .body           { background-position: 0 0;           width: 411px; height: 223px; position: absolute; left: 0;      top: 85px; }
	.santa .character .character-head { background-position: -227px -356px; width: 140px; height: 192px; position: absolute; left: 143px;  top: 5px; }
	.santa .character .head           { background-position: -227px -356px; width: 140px; height: 192px; position: absolute; left: 0;      top: 0; }
	.santa .character .glasses        { background-position: -319px -294px; width: 81px;  height: 33px;  position: absolute; left: 35px;   top: 52px; }
	.santa .character .stache-right   { background-position: -111px -618px; width: 74px;  height: 83px;  position: absolute; left: 78px;   top: 28px; z-index: 1; }
	.santa .character .stache-left    { background-position: -111px -554px; width: 79px;  height: 64px;  position: absolute; left: 2px;    top: 54px; z-index: 2; }
	.santa .character .nose           { background-position: -318px -327px; width: 35px;  height: 19px;  position: absolute; left: 62px;   top: 71px; z-index: 3; }
	.santa .character .hand-left      { background-position: -0px -356px;   width: 227px; height: 198px; position: absolute; left: 85px;   top: 85px; }
	.santa .character .hand-right     { background-position: -227px -548px; width: 122px; height: 198px; position: absolute; left: 345px;  top: 48px; }
	.santa .ticklish-zone             { z-index: 4; cursor: pointer;        width: 60px;  height: 40px;  position: absolute; left: 180px;  top: 70px; }
	.santa .ticklish-zone:before      { content: ''; display: block;        width: 60px;  height: 60px;  position: absolute; left: -105px; top: 245px; }
	.santa .ticklish-zone:after       { content: ''; display: block;        width: 60px;  height: 60px;  position: absolute; left: 140px;  top: 210px; }

	/* Pere Noel défaut */
	.santa .character .stache-left  { animation: left-stache-shake 10s; animation-delay: 5s; animation-fill-mode: both; transform-origin: right center; }
	.santa .character .stache-right { animation: right-stache-shake 10s; animation-delay: 5s; animation-fill-mode: both; transform-origin: left center; }
	.santa .character .hand-right   { animation: hand-shaking 7s linear infinite alternate; transform-origin: 55px 165px; }

	/* Pere Noel chatouilleux */
	.santa .ticklish-zone:hover ~ .body       { animation: tickle-body infinite 0.5s; transform-origin: center bottom; }
	.santa .ticklish-zone:hover ~ .leg-right  { animation: tickle-leg 0.3s infinite; }
	.santa .ticklish-zone:hover ~ .leg-left   { animation: tickle-leg 0.3s infinite; animation-delay: 0.2s; }
	.santa .ticklish-zone:hover ~ .hand-left  { animation: tickle-arm 0.5s infinite; }
	.santa .ticklish-zone:hover ~ .hand-right { animation: tickle-arm 0.5s infinite; }
	.santa .ticklish-zone:hover ~ .character-head { animation: tickle-head 0.5s infinite; transform-origin: center bottom; }
	.santa .ticklish-zone:hover ~ .character-head .stache-left  { animation: tickle-stache-left 0.3s; transform-origin: right center; }
	.santa .ticklish-zone:hover ~ .character-head .stache-right { animation: tickle-stache-right 0.3s; transform-origin: left center; animation-delay: 0.2s;}

	.imp { position: absolute; display: block; top: 340px; left: calc(50% + 343px); }
	.imp .imp-body { position: absolute; left: 0px; top: 82px; }
	.imp .imp-head { position: absolute; left: 40px; top: 0px; }

	.imp .imp-head { animation: head-shaking 8s infinite; animation-fill-mode: both; transform-origin: center bottom; }
}


/*
* CTAs
*/
.homepage-ctas {
	margin: rem(24px);

	.button--homepage-link {
		display: inline-block;
		height: rem(50px);
		margin-bottom: rem(12px);
		line-height: rem(30px);
		border-radius: rem(25px);
	}

	.button--homepage-example {

	}
}



/*
* Comment ça marche ?
*/
.homepage-title {
	font-size: 1.375em;
	color: #ec6b4b;
	margin-bottom: rem(30px);
	background: #fff;
	padding: 5px;
	position: relative;

	&:after {
		background: url('/images/homepage/string.png') no-repeat top center;
		background-size: 100% auto;
		content: '';
		display: block;
		height: rem(1223px);
		left: 50%;
		max-width: 100%;
		position: absolute;
		top: 100%;
		transform: translateX(-50%);
		width: rem(1320px);

		@media only screen and (max-width: rem(1160px)) {
			height: rem(790px);
			width: rem(850px);
		}

		@media only screen and (max-width: rem(850px)) {
			display: none;
		}
	}
}

.homepage-howto {
	list-style-type: none;
	margin: 0 auto;
	width: 100%;
	position: relative;

	@media only screen and (max-width: rem(1160px)) {
		max-width: rem(850px);
	}

	li {
		display: flex;
		flex-direction: column;
		margin-bottom: rem(50px);
		width: rem(428px);

		&:nth-child(1) { margin: rem(110px) 0 0 rem(70px); }
		&:nth-child(2) { margin: rem(-345px) 0 0 rem(635px); }
		&:nth-child(3) { margin: rem(180px) 0 0 rem(15px); }
		&:nth-child(4) { margin: rem(-430px) 0 0 rem(570px); }

		.help__bubble {
			display: inline-flex;
			width: auto;

			.homepage-list--count {
				background-image: url('/images/homepage/puce-liste.gif');
				display: inline-block;
				font-size: 0;
				height: rem(40px);
				margin-right: rem(10px);
				width: rem(40px);

				&.count2 { background-position: 0 rem(-50px); }
				&.count3 { background-position: 0 rem(-98px); }
				&.count4 { background-position: 0 rem(-147px); }
			}

			p {
				margin: 0;
				text-align: left;
			}
		}

		img {
			border: rem(10px) solid #eee;
			border-radius: rem(10px);
			box-shadow: 0 rem(1px) rem(3px) 0 rgba(0, 0, 0, .3);
			display: block;
			max-width: 100%;
		}

		@media only screen and (max-width: rem(1160px)) {
			width: rem(300px);

			&:nth-child(1) { margin: rem(50px) 0 0 rem(95px); }
			&:nth-child(2) { margin: rem(-320px) 0 0 rem(420px); }
			&:nth-child(3) { margin: rem(120px) 0 0 rem(110px); }
			&:nth-child(4) { margin: rem(-350px) 0 0 rem(450px); }
		}

		@media only screen and (max-width: rem(850px)) {
			margin: 0 auto rem(30px) !important;
		}
	}
}


/*
* Bottom
*/
.home-bottom {
	margin: rem(150px) 0 0;
	padding: 0;

	.home-description {
		color: #333;
		font-size: rem(16px);
		line-height: rem(20px);
		margin-top: rem(32px);
		text-align: left;
	}

	.home-description-title {
		color: $main-color;
		display: block;
		font-size: rem(18px);
		line-height: rem(32px);
		text-align: left;
	}

	.grid-column:before {
		background-size: auto 100% !important;
		content: '';
		display: block;
		height: rem(25px);
		width: rem(25px);
	}

	.grid-column--multisite:before  { background: url(../../images/homepage/icons.png) no-repeat -0px  top; }
	.grid-column--subscribe:before  { background: url(../../images/homepage/icons.png) no-repeat -25px top; }
	.grid-column--responsive:before { background: url(../../images/homepage/icons.png) no-repeat -50px top; }
	.grid-column--private:before    { background: url(../../images/homepage/icons.png) no-repeat -75px top; }

	.grid-column {
		@media only screen and (min-width: rem(480px)) and (max-width: rem(600px)) {
			margin: 0 rem(32px);
		}

		@media only screen and (min-width: rem(600px)) {
			width: calc(50% - #{$gutter * 5});
			margin: calc(#{$gutter * 2.5});
		}

		@media only screen and (min-width: rem(1024px)) {
			width: calc(25% - #{$gutter * 5});
			margin: calc(#{$gutter * 2.5});

			.home-description {
				margin-top: 0;
			}
		}
	}

	@media only screen and (min-width: rem(600px)) and (max-width: rem(768px)) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
	}
}
